<template>
  <div id="app" style="height: 100%;">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
	
}
</script>
<style>
	
</style>
