<template>
  <div>
    <dv-full-screen-container>
		<div v-show="showContent==false" class="flex flex-column align-center" style="position: absolute;top: 0;right: 0;left: 0;bottom: 0;background-color:rgba(255,255,255);z-index: 100;">
			<i class="el-icon-warning" style="font-size: 6rem;color: #e63e3e;margin-top: 20%;"></i>
			<span style="color: #000000;font-size: 2rem;display: inline-block;margin-top: 10%;">请使用电脑打开网页</span>
		</div>
		<div v-show="showVideoDialog" class="flex align-center justify-center" style="position: absolute;top: 0;right: 0;left: 0;bottom: 0;background-color:rgba(0,0,0,.5);z-index: 100;">
			<div style="width: 50%;height: 50%;margin-top: -15%;">
				<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
					<dv-border-box-12 ref="videoRef" style="position: relative;width: 770px;height:490px;box-sizing: border-box;padding: 10px;">
						<div style="position: absolute;z-index: 99999;right:0;top:-20px;right:-20px">
							<i @click="closeDialog" class="el-icon-circle-close" style="color: #ffffff;font-size: 2rem;"></i>
						</div>
						<div style="background-color:transparent; position: absolute;z-index: 10000;width: 100%;height: 100%;"></div>
						<div style="width: 750px;height:470px" id="showVideoId"></div>
						<!-- <video :loop="true" :autoplay="true" :src="videoUrl" style="width: 100%;height:100%;object-fit: cover;" muted></video> -->
					</dv-border-box-12>
				</div>
			</div>
		</div>
		<div style="height: 7%;box-sizing: border-box;display: flex;justify-content: space-between;">
			<dv-decoration-8 style="width:29%" />
			<div style="width: 42%;position: relative;">
				<span class="text-shadow-drop-center" style="color: #96fcfc;font-weight: bold;font-size: 1.5rem;position: absolute;left: 50%;transform: translate(-50%,15%);">我和你种植基地实时展示</span>
				<dv-decoration-5 style="width:100%;position: absolute;transform: translate(0,30%);"/>
			</div>
			<dv-decoration-8 :reverse="true" style="width:29%" />
		</div>
		<div style="height: 2%;box-sizing: border-box;display: flex;align-items: center;padding-left: 1.5%;font-weight: bold;">
			<span style="color: #ffffff;">{{showTime}}</span>
		</div>
		<div style="height: 91%;box-sizing: border-box;display: flex;justify-content: center;align-items: center;">
			<div style="width: 97.2%;height: 97.9%;display: flex;box-sizing: border-box;flex-direction: column;justify-content: space-between;">
				<div style="height: 66%;box-sizing: border-box;display: flex;">
					<div style="width: 24%;box-sizing: border-box;">
						<dv-border-box-8 ref="baseListRef">
							<div class="border-box-content" style="padding: 3%;box-sizing: border-box;display: flex;justify-content: space-between;flex-direction: column;">
								<div style="height:45%;">
									<div style="height: 18%;display: flex;align-items: center;">
										<div style="width: 0.8%;height: 75%;background-color: #26b3ee;"></div>
										<span style="color: #ffffff;font-size: 1rem;margin-left: 2%;">基地列表</span>
										<dv-decoration-6 style="width:60%;height:70%;margin-left: auto;" />
									</div>
									<div style="height: 73%;display: flex;display: flex;flex-wrap: wrap;margin-top: 3%;">
										<div @click="clickBase(index)" v-for="(item,index) in baseList" :key="index" style="width: 25%;height: 50%;display: flex;align-items: center;justify-content:center;flex-direction: column;" class="baseClass">
											<template v-if="index==currentBase&&item.isclick==1">
												<dv-border-box-8 :color="['yellow','yellow']">
													<select-rect :item = 'item'></select-rect>
												</dv-border-box-8>
											</template>
											<template v-else>
												<div :class="item.isclick==1?'':'jinyong'" :style="item.isclick==1?'opacity: 1':'opacity: 0.5'" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
													<img style="width:85%;" :src="item.img"/>
													<span style="color: #ffffff;font-size: .8rem;margin-top: 5%;">{{item.name}}</span>
												</div>
											</template>
										</div>
									</div>
								</div>
								<div style="height: 38%;box-sizing: border-box;">
									<div style="height: 8%;display: flex;align-items: center;">
										<div style="width: 1%;height: 75%;background-color: #26b3ee;align-self: flex-start;"></div>
										<div style="width: 100%;margin-left: 2%;">
											<span style="color: #ffffff;font-size: 1rem;margin-bottom: 1%;display: inline-block;">室外环境</span>
											<dv-decoration-10 style="width:90%;height:10px"/>
										</div>
									</div>
									<div style="height: 7%;"></div>
									<div style="height: 85%;display: flex;flex-wrap: wrap;">
										<!--室外环境start-->
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.text}}</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">天气</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.temp}}℃</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">温度</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.humidity}}%</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">湿度</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.windScale}}</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">风力</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.windSpeed}}公里/小时</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">风速</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.windDir}}</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">风向</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size:.8rem;font-weight: bold;font-style: italic;">{{weather.precip}}毫米</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">雨量</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;height: 31%;display: flex;align-items: center;justify-content: center;">
											<div style="width: 85%;display: flex;justify-content: center;flex-direction: column;align-items: center;height: 100%;">
												<span class="scale-up-center-text" style="color: #1cc9d9;font-size: .8rem;font-weight: bold;font-style: italic;">{{weather.pressure}}百帕</span>
												<div style="margin-top: 10%;height: 60%;width: 90%;position: relative;margin-top: 3%;display: flex;align-items: center;justify-content: center;">
													<span style="color: #ffffff;font-size: .9rem;font-weight: bold;margin-bottom: 12%;">气压</span>
													<img src="@/assets/ev_bg.gif" style="width: 100%;height: 100%;position: absolute;z-index: -10;"/>
												</div>
											</div>
										</div>
										<!--室外环境end-->
									</div>
								</div>
							</div>
						</dv-border-box-8>
					</div>
					<div style="width: 52%;box-sizing: border-box;position: relative;">
						<div style="width:19%;height:27%;position: absolute;z-index: 10;left: 2%;top: 3%;">
							<dv-decoration-9 style="width:100%;height:100%;">
								<span style="color: #6cb7de;font-weight: bold;font-size: 1.4rem;">{{baseList[currentBase].name}}</span>
							</dv-decoration-9>
						</div>
						<div style="width:18%;height:30%;position: absolute;z-index: 10;bottom: 3%;left: 3%;">
							<dv-decoration-12 style="width:100%;height:100%;" />
						</div>
						<dv-flyline-chart-enhanced :config="config" style="width:100%;height:100%;" />
					</div>
					<div style="width: 24%;box-sizing: border-box;display: flex;flex-direction: column;justify-content: space-between;">
						<div style="height: 58%;box-sizing: border-box;">
							<dv-border-box-8 ref="videoListRef">
								<div class="border-box-content" style="padding: 3%;box-sizing: border-box;">
									<div style="height: 15%;display: flex;align-items: center;">
										<div style="width: 1%;height: 50%;background-color: #26b3ee;"></div>
										<span style="color: #ffffff;font-size: 1rem;margin-left: 2%;">基地实时监控</span>
										<dv-decoration-6 style="width:60%;height:40%;margin-left: auto;" />
									</div>
									<div style="height: 85%;display: flex;box-sizing: border-box;flex-wrap: wrap;">
										<div @click="callVideo(item)" v-for="(item,index) in videos" :key="index" style="position: relative;width: 50%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
												<img :src="item.image" style="width: 90%;height: 90%;"/>
											</div>
										</div>
										<!-- <div @click="callVideo(item)" v-for="(item,index) in videos" :key="index" style="position: relative;width: 50%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<div style="width: 100%;height: 100%;position: absolute;background-color: transparent;z-index: 1000;"></div>
											<div class="viode-rect" @click="callVideo" :id="'video-container-'+index" style="width: 95%;height:90%;border: 1px solid #1cc9d9 !important;"></div>
										</div> -->
									</div>
									<!-- <div style="height: 220px;display: flex;box-sizing: border-box;flex-wrap: wrap;">
										<div @click="callVideo(item)" v-for="(item,index) in videos" :key="index" style="position: relative; width: 50%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<div style="width: 130px;height: 100px;position: absolute;background-color: transparent;z-index: 1000;"></div>
											<div :id="'video-container-'+index" style="width: 130px;height:100px;"></div>
										</div>
									</div> -->
								</div>
							</dv-border-box-8>
						</div>
						<div style="height: 40%;box-sizing: border-box;">
							<dv-border-box-8 ref="zongheRef">
								<div class="border-box-content" style="padding: 3%;box-sizing: border-box;">
									<div style="height: 15%;display: flex;align-items: center;">
										<div style="width: 1%;height: 50%;background-color: #26b3ee;"></div>
										<span style="color: #ffffff;font-size: 1rem;margin-left: 2%;">环境综合数据</span>
										<dv-decoration-6 style="width:60%;height:70%;margin-left: auto;" />
									</div>
									<div style="height: 73%;display: flex;box-sizing: border-box;flex-wrap: wrap;">
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/water.png" style="width: 55%;height: 85%;"/>
											<div class="flex flex-column" style="width: 40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">温度</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .6rem;">{{zongheData.kqwd}}℃</span>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/water.png" style="width: 55%;height: 85%;"/>
											<div class="flex flex-column" style="width: 40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">湿度</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .6rem;">{{zongheData.kqsd}}%</span>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/light.png" style="width: 55%;height: 85%;"/>
											<div class="flex flex-column" style="width: 40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">光照</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .6rem;">{{zongheData.gz}}</span>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/tem.png" style="width: 55%;height: 85%;"/>
											<div class="flex flex-column" style="width: 40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">土壤温度</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .7rem;">{{zongheData.trwd}}℃</span>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/water.png" style="width: 55%;height: 85%;"/>
											<div class="flex flex-column" style="width: 40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">土壤湿度</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .7rem;">{{zongheData.trsd}}%</span>
											</div>
										</div>
										<div style="width: 33.3%;box-sizing: border-box;display: flex;align-items: center;justify-content: center;height: 50%;">
											<img class="scale-up-center" src="../../assets/co2.png" style="width:55%;height: 85%;"/>
											<div class="flex flex-column" style="width:40%;box-sizing: border-box;margin-left: 5%;">
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .55rem;">二氧化碳</span>
												<div style="height: 10%;"></div>
												<span style="color: #ffffff;font-weight: bold;font-style: italic;font-size: .6rem;">{{zongheData.co2}}ppm</span>
											</div>
										</div>
									</div>
								</div>
							</dv-border-box-8>
						</div>
					</div>
				</div>
				<!--xxx-->
				<div style="height: 33%;display: flex;justify-content: space-between;">
					<div style="width: 24%;box-sizing: border-box;">
						<dv-border-box-1 ref="ch1">
							<div v-if="kongqiwenshiduOp" class="border-box-content" style="width: 100%;padding-left: 5%;box-sizing: border-box;padding-top: 8%;position: relative;">
								<div style="position: absolute;z-index: 100;left: 10%;">
									<span style="color: #ffffff;font-weight: bolder;font-size: 1rem;position: absolute;">空气温湿度数据分析({{baseList[currentBase].name}})</span>
									<img src="../../assets/title.png" style="width: 100%;height: 20%;margin-top: 2%;">
								</div>
								<dv-charts :option="kongqiwenshiduOp"/>
							</div>
							<div v-else class="border-box-content flex align-center justify-center" style="width: 100%;box-sizing: border-box;height: 100%;">
								<span style="color: #e63e3e;font-size: 1.5rem;">暂无数据</span>
							</div>
						</dv-border-box-1>
					</div>
					<div style="width: 24%;box-sizing: border-box;">
						<dv-border-box-1 ref="ch2">
							<div v-if="turangwenshiduOp" class="border-box-content" style="width: 100%;padding-left: 5%;box-sizing: border-box;padding-top: 8%;position: relative;">
								<div style="position: absolute;z-index: 100;left: 10%;">
									<span style="color: #ffffff;font-weight: bolder;font-size: 1rem;position: absolute;">土壤温湿度数据分析({{baseList[currentBase].name}})</span>
									<img src="../../assets/title.png" style="width: 100%;height: 20%;margin-top: 2%;">
								</div>
								<dv-charts :option="turangwenshiduOp"/>
							</div>
							<div v-else class="border-box-content flex align-center justify-center" style="width: 100%;box-sizing: border-box;height: 100%;">
								<span style="color: #e63e3e;font-size: 1.5rem;">暂无数据</span>
							</div>
						</dv-border-box-1>
					</div>
					<div style="width: 24%;box-sizing: border-box;">
						<dv-border-box-1 ref="ch3">
							<div v-if="guangzhaoOp" class="border-box-content" style="width: 100%;padding-left: 5%;box-sizing: border-box;padding-top: 8%;position: relative;">
								<div style="position: absolute;z-index: 100;left: 10%;">
									<span style="color: #ffffff;font-weight: bolder;font-size: 1rem;position: absolute;">光照数据分析({{baseList[currentBase].name}})</span>
									<img src="../../assets/title.png" style="width: 100%;height: 20%;margin-top: 2%;">
								</div>
								<dv-charts :option="guangzhaoOp"/>
							</div>
							<div v-else class="border-box-content flex align-center justify-center" style="width: 100%;box-sizing: border-box;height: 100%;">
								<span style="color: #e63e3e;font-size: 1.5rem;">暂无数据</span>
							</div>
						</dv-border-box-1>
					</div>
					<div style="width: 24%;box-sizing: border-box;">
						<dv-border-box-1 ref="ch4">
							<div v-if="co2Op" class="border-box-content" style="width: 100%;padding-left: 5%;box-sizing: border-box;padding-top: 8%;position: relative;">
								<div style="position: absolute;z-index: 100;left: 10%;">
									<span style="color: #ffffff;font-weight: bolder;font-size: 1rem;position: absolute;">二氧化碳数据分析({{baseList[currentBase].name}})</span>
									<img src="../../assets/title.png" style="width: 100%;height: 20%;margin-top: 2%;">
								</div>
								<dv-charts :option="co2Op"/>
							</div>
							<div v-else class="border-box-content flex align-center justify-center" style="width: 100%;box-sizing: border-box;height: 100%;">
								<span style="color: #e63e3e;font-size: 1.5rem;">暂无数据</span>
							</div>
						</dv-border-box-1>
					</div>
				</div>
			</div>
		</div>
	</dv-full-screen-container>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import axios from 'axios'
let nowTimeTimer = null
let changeDataTimer = null
let videoTimer = null
import EZUIKit from "ezuikit-js";
import selectRect from '@/components/selectRect.vue'
let showDialogPlayer = null
let videoPlayers = []
export default {
  //   beforeRouteEnter(to, from, next) {
  //       if(!localStorage.getItem("big_screen")){
		// 	Message.error('请先登录')
		// 	next(`/`)
		// }else if(localStorage.getItem("big_screen")){
		// 	next(vm => {
		// 		vm.$http.get(`${vm.$baseUrl}/api/user/isLogin?token=${localStorage.getItem("big_screen")}`).then(res=>{
		// 			next()
		// 		}).catch(err=>{
		// 			console.log('-------------------66666',err.response.data.msg)
		// 			Message.error(err.response.data.msg)
		// 			next(`/`)
		// 		})
		// 	})
			
		// }
        
  //   },
	data() {
		return {
			changeData:60000,
			/**
			 * 天气
			 */
			weather:{
				text:'',
				temp:'',
				humidity:'',
				windScale:'',
				windSpeed:'',
				windDir:'',
				precip:'',
				pressure:''
			},
			/**
			 * 综合环境数据
			 */
			zongheData:{
				cgq: "",
				co2: "",
				gz: "",
				kqsd: "",
				kqwd: "",
				trsd: "",
				trwd: ""
			},
			/**
			 * 是否显示页面内容
			 */
			showContent:false,
			/**
			 * 显示的时间
			 */
			showTime:'',
			/**
			 * 是否显示视频播放对话框
			 */
			showVideoDialog:false,
			/**
			 * 地图数据
			 */
			config:{},
			/**
			 * 监控视频
			 */
			videos:[],
			/**
			 * 当前选择基地
			 */
			currentBase: 0,
			/**
			 * 基地数据
			 */
			baseList:[
				{
					name:""
				}
			],
			/**
			 * 空气温湿度配置
			 */
			kongqiwenshiduOp:{},
			/**
			 * 土壤温湿度
			 */
			turangwenshiduOp:{},
			/**
			 * 光照数据分析
			 */
			guangzhaoOp:{},
			/**
			 * 二氧化碳分析
			 */
			co2Op:{},
			width:0,
			height:0
		}
	},
	created() {
		this.showContent = true
		// let brower = navigator.userAgent
		// if(brower.indexOf("Android")!=-1||brower.indexOf("iPhone")!=-1){
		// 	this.showContent = false
		// }else{
		// 	this.showContent = true
		// }
		
	},
	components:{
		selectRect
	},
	mounted() {
		window.addEventListener('resize', () => {
			location.href = location.href
		});
		nowTimeTimer = setInterval(()=>{
			let time = new Date()
			let year = time.getFullYear()
			let month = time.getMonth()+1
			let day = time.getDate()
			let hour = time.getHours()<10?'0'+time.getHours():time.getHours()
			let min = time.getMinutes()<10?'0'+time.getMinutes():time.getMinutes()
			let second = time.getSeconds()<10?'0'+time.getSeconds():time.getSeconds()
			let week = this.formateWeek(time.getDay())
		    this.showTime = year+"年"+month+'月'+day+'日 '+hour+':'+min+':'+second+' '+week
		},1000)
		//获取数据刷新时间
		this.getDataChangeTime()
		//获取视频刷新时间
		this.getVideoChangeTime()
		//获取基地列表
		this.getBaseList()
		//baseListBorder
		//获取天气
		//this.getCitysLocation()
		//初始化token
		//this.getVisibleVideo()
		
		
	},
	methods: {
		getVideoChangeTime(){
			this.$http.post(`${this.$baseUrl}/api/ys/timing`).then(res=>{
				if(res.data.code==1){
					let time = parseInt(res.data.data)*60000
					videoTimer = setInterval(()=>{
						this.initVideo(this.currentBase)
					},time)
				}
			})
			// videoTimer = setInterval(()=>{
			// 	console.log("更新视频")
			// 	//初始化摄像头
			// 	this.initVideo(this.currentBase)
			// },10000)
		},
		getDataChangeTime(){
			this.$http.post(`${this.$baseUrl}/api/cgq/config`).then(res=>{
				if(res.data.code==1){
					this.changeData = parseInt(res.data.data.time)*1000
					changeDataTimer = setInterval(()=>{
						this.getLastest()
						this.getChartsData()
						this.getCitysLocation()
					},this.changeData)
				}
			})
		},
		/**
		 * 获取统计数据
		 */
		getChartsData(){
			let city_id = this.baseList[this.currentBase].id
			this.$http.get(`${this.$baseUrl}/api/cgq/statistics?type=2&city_id=${city_id}`).then(res=>{
				if(res.data.code==1){
					if(res.data.data.length==0){
						this.kongqiwenshiduOp=false
						this.turangwenshiduOp = false
						this.guangzhaoOp = false
						this.co2Op = false
					}else{
						let statData = res.data.data
						let hours = statData.hour
						//空气温湿度
						this.kongqiwenshiduOp = {
							title: {
							  text: ``,
							  style: {
								  fill: '#ffffff',
								  fontSize: 17,
								  fontWeight: 'bold',
								  textAlign: 'center',
								  textBaseline: 'bottom'
							  },
							  offset:[-90, -35]
							},
							legend: {
							  right:'10%',
							  top:'15%',
							  data: [{
									name:'温度',
									color:'rgba(255, 159, 41)'
								}, {
									name:'湿度',
									color:'rgba(0, 231, 242)'
								}],
								textStyle: {
								  fontFamily: 'Arial',
								  fontSize: 10,
								  fill: '#ffffff'
								}
							},
							xAxis: {
							  data: hours,
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							yAxis: {
							  data: 'value',
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							series: [
							  {
							    name: '温度',
							    data: statData.kqwd.map(v=>parseFloat(v)),
							    type: 'bar',
							    gradient: {
								 color: ['#ff6e06', '#fed34f']
							    }
							  },
							  {
							    name: '湿度',
							    data: statData.kqsd.map(v=>parseFloat(v)),
							    type: 'bar',
							    gradient: {
								 color: ['#37a2da', '#67e0e3']
							   }
							  }
							]
						}
						//土壤温湿度
						this.turangwenshiduOp = {
							title: {
							  text: ``,
								style: {
								  fill: '#ffffff',
								  fontSize: 17,
								  fontWeight: 'bold',
								  textAlign: 'center',
								  textBaseline: 'bottom'
								},
								offset:[-90, -35]
							},
							legend: {
							  right:'10%',
							  top:'15%',
							  data: [{
							  	name:'温度',
							  	color:'rgba(255, 159, 41)'
							  }, {
							  	name:'湿度',
							  	color:'rgba(0, 231, 242)'
							  }],
							  textStyle: {
							    fontFamily: 'Arial',
							    fontSize: 13,
							    fill: '#ffffff'
							  }
							},
							xAxis: {
							  data: hours,
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							yAxis: {
							  data: 'value',
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							series: [
							  {
							    name: '温度',
							    data: statData.trwd.map(v=>parseFloat(v)),
							    type: 'line',
							    smooth: true,
								  linePoint: {
									radius: 0
								  },
								  lineArea:{
									show:true,
									style:{
										fill:'rgba(255, 159, 41,.5)'
									}
								  },
								  lineStyle: {
									lineWidth: 2,
									stroke:'rgb(255, 159, 41)'
								  }
							  },
							  {
							    name: '湿度',
							    data: statData.trsd.map(v=>parseFloat(v)),
							    type: 'line',
							    smooth: true,
								  linePoint: {
									radius: 0
								  },
								  lineArea:{
									show:true,
									style:{
										fill:'rgba(0, 231, 242,.5)'
									}
								  },
								  lineStyle: {
									lineWidth: 2,
									stroke:'rgba(0, 231, 242)'
								  }
							  }
							]
						}
						//光照分析
						this.guangzhaoOp = {
							title: {
							    text: ``,
								style: {
								  fill: '#ffffff',
								  fontSize: 17,
								  fontWeight: 'bold',
								  textAlign: 'center',
								  textBaseline: 'bottom'
								},
								offset:[-120, -35]
							},
							legend: {
							  right:'10%',
							  top:'15%',
							  data: [{
							  	name:'光照',
							  	color:'rgba(255, 159, 41)'
							  }],
							  textStyle: {
							    fontFamily: 'Arial',
							    fontSize: 13,
							    fill: '#ffffff'
							  }
							},
							xAxis: {
							  data: hours,
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							yAxis: {
							  data: 'value',
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							series: [
							  {
							    name: '光照',
							    data: statData.gz.map(v=>parseFloat(v)),
							    type: 'line',
							    smooth: true,
								  linePoint: {
									radius: 15,
									style: {
									  fill: 'rgba(255, 159, 41,.5)',
									  stroke:'rgba(255, 159, 41)',
									  lineWidth: 1
									}
								  },
								  lineStyle: {
									lineWidth: 0.0000001,
									stroke:'rgb(255, 159, 41)'
								  }
							  }
							]
						}
						this.co2Op = {
							title: {
							  text: ``,
								style: {
								  fill: '#ffffff',
								  fontSize: 17,
								  fontWeight: 'bold',
								  textAlign: 'center',
								  textBaseline: 'bottom'
								},
								offset:[-90, -35]
							},
							legend: {
							  right:'10%',
							  top:'15%',
							  data: [{
									name:'二氧化碳浓度',
									color:'rgba(255, 159, 41)'
								}],
								textStyle: {
								  fontFamily: 'Arial',
								  fontSize: 13,
								  fill: '#ffffff'
								}
							},
							xAxis: {
							  data: hours,
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							yAxis: {
							  data: 'value',
								axisLabel:{
									style: {
									  fill: '#ffffff',
									  fontSize: 10,
									  rotate: 0
									}
								}
							},
							series: [
							  {
							    name: '二氧化碳浓度',
							    data: statData.co2.map(v=>parseFloat(v)),
							    type: 'bar',
								  gradient: {
									color: ['#fe6948', '#fabc2d']
								  },
								  barStyle: {
									stroke: '#fb8041'
								  }
							  }
							]
						}
					}
					//更新视图高度
					this.$refs['ch1'].initWH()
					this.$refs['ch2'].initWH()
					this.$refs['ch3'].initWH()
					this.$refs['ch4'].initWH()
				}
			})
		},
		/**
		 * 获取最新数据
		 */
		getLastest(){
			let city_id = this.baseList[this.currentBase].id
			this.$http.post(`${this.$baseUrl}/api/cgq/lastest`,{
				city_id
			}).then(res=>{
				if(res.data.code==1){
					this.zongheData = res.data.data
					this.$refs['zongheRef'].initWH()
				}
			})
		},
		/**
		 * 选择地图坐标
		 */
		selectMapLocation(index){
			let points = this.baseList.map((v,k)=>{
				if(k==index){
					return {
						name: v.name,
						coordinate: v.coordinate.split(","),
						icon: {
							src: 'https://jttyapp.whnjkcy.com/assets/img/tb2.png',
							width: 20,
							height: 20
						},
						text: {
							color: '#f5f302'
						}
					}
				}else{
					return {
						name: v.name,
						coordinate: v.coordinate.split(","),
						text: {
							color: '#ffffff'
						}
					}
				}
			})
			let lines = [
				{
				  source: this.baseList[0].name,
				  target: this.baseList[0].name,
				  width:0
				}
			]
			this.config = {
				points: points,
				lines: lines,
				icon: {
					show: true,
					src: 'https://jttyapp.whnjkcy.com/assets/img/tb1.png'
				},
				text: {
					show: true,
				},
				bgImgSrc: 'https://jttyapp.whnjkcy.com/assets/img/bgg.png'
			}
			
		},
		/**
		 * 获取基地列表
		 */
		getBaseList(){
			this.$http.post(`${this.$baseUrl}/api/cgq/city`).then((res) => {
				if(res.data.code==1){
					this.baseList = res.data.data.map(v=>{
						return {
							id:v.id,
							name:v.name,
							lat:v.long_lat.split(",")[1],
							lon:v.long_lat.split(",")[0],
							img:v.image,
							url:v.url,
							coordinate:v.coordinate,
							isclick:v.isclick,
							images:v.images
						}
					})
					console.log('this.baseList---------------',this.baseList)
					// this.baseList.push({
					// 	coordinate: "0.59,0.54",
					//     id: 2,
					// 	img: "http://cgq.whnjk365.com/uploads/20220903/6b97ee99d5206dc89b27af604ad844fc.jpg",
					// 	isclick: "2",
					// 	lat: "33.07766",
					// 	lon: "107.02861",
					// 	name: "温县",
					// 	url: "ezopen://open.ys7.com/C70388722/1.hd.live,ezopen://open.ys7.com"
					// })
					//选取地图的坐标
					this.selectMapLocation(this.currentBase)
					//获取天气
					this.getCitysLocation()
					this.$refs['baseListRef'].initWH()
					//console.log(this.$refs['selRect'])
					//初始化摄像头
					this.initVideo(this.currentBase)
					//获取最新数据
					this.getLastest()
					//获取统计数据
					this.getChartsData()
				}
			})
		},
		//初始化token
		initVideo(index){
			  // let imgs = this.baseList[index].images.split(",")
			  // this.videos = imgs
			  // if(this.videos.length>0&&this.showContent){
				 //  this.$nextTick(()=>{
					//   let video = document.getElementsByClassName("viode-rect")[0].getBoundingClientRect()
					//   this.width = video.width
					//   this.height = video.height
					//   console.log(this.videos)
				 //  })
			  // }
			
			
			this.$http.post(`${this.$baseUrl}/api/ys/get_access_token`).then((res) => {
			  //console.log(this.baseList[index])
			  let urls = this.baseList[index].url.split(",")
			  let array = []
			  urls.forEach((v,k)=>{
				array.push({
					url:v,
					accessToken:res.data.data,
					image:this.baseList[index].images.split(",")[k]
				})
			  })
			  this.videos = array
			  if(this.videos.length>0&&this.showContent){
				  this.$nextTick(()=>{
					  let video = document.getElementsByClassName("viode-rect")[0].getBoundingClientRect()
					  this.width = video.width
					  this.height = video.height
				  })
			  }
			})
			
			
			
			
			
			
			
			
			
			
			
			
			
			// this.$http.post(`${this.$baseUrl}/api/ys/get_access_token`).then((res) => {
			//   let urls = this.baseList[index].url.split(",")
			//   let array = []
			//   urls.forEach(v=>{
			// 	array.push({
			// 		url:v,
			// 		accessToken:res.data.data
			// 	})
			//   })
			//   this.videos = array
			//   if(this.videos.length>0&&this.showContent){
			// 	  this.$nextTick(()=>{
			// 		  let video = document.getElementsByClassName("viode-rect")[0].getBoundingClientRect()
			// 		  this.width = video.width
			// 		  this.height = video.height
			// 		  console.log(this.width,this.height)
			// 		  this.videos.forEach((v,k)=>{
			// 			 let player = new EZUIKit.EZUIKitPlayer({
			// 			   id:'video-container-'+k, // 视频容器ID
			// 			   accessToken:v.accessToken,
			// 			   url:v.url,
			// 			   template:'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
			// 			   audio:false,
			// 			   width:this.width,
			// 			   height:this.height,
			// 			   handleSuccess:()=>{
			// 				  player.isPlay = true
			// 				  setInterval(()=>{
			// 					if(player.isPlay){
			// 						player.stop()
			// 						player.isPlay = false
			// 					}else{
			// 						player.play()
			// 						player.isPlay = true
			// 					}
			// 				  },5000)
			// 			   },
			// 			 })
			// 		  })
			// 	  })
			//   }
			// })
		},
		/**
		 * 获取城市天气"lat":"34.36877","lon":"118.34582"
		 */
		getCitysLocation(){
			let code= this.baseList[this.currentBase].lon+','+this.baseList[this.currentBase].lat
			this.$http.get(`https://devapi.qweather.com/v7/weather/now?location=${code}&key=95513c7b370c41a6b96a954028d23825`).then((res) => {
			  this.weather = res.data.now
			  //this.getTest(code)
			})
		},
		// getTest(code){
		// 	https://devapi.qweather.com/v7/air/now?location=101010100&key=你的KEY
		// 	this.$http.get(`https://devapi.qweather.com/v7/air/now?location=${code}&key=95513c7b370c41a6b96a954028d23825`).then((res) => {
		// 	  console.log('空气质量--------------',res)
			  
		// 	})
		// },
		/**
		 * 转化周几
		 */
		formateWeek(week){
			switch (week){
				case 1:
					return '星期一'
					break;
				case 2:
					return '星期二'
					break;
				case 3:
					return '星期三'
					break;
				case 4:
					return '星期四'
					break;
				case 5:
					return '星期五'
					break;
				case 6:
					return '星期六'
					break;
				case 0:
					return '星期日'
					break;
				default:
					break;
			}
		},
		/**
		 * 关闭对话框
		 */
		closeDialog(){
			this.showVideoDialog = false
			showDialogPlayer.stop()
			showDialogPlayer=null
			console.log("关闭了")
			//this.initVideo(this.currentBase)
		},
		/**
		 * 调取视频对话框
		 */
		callVideo(item) {
			let scwidth = document.body.clientWidth
			this.showVideoDialog = true
			this.$refs.videoRef.initWH()
			showDialogPlayer = new EZUIKit.EZUIKitPlayer({
			  id:'showVideoId', // 视频容器ID
			  accessToken:item.accessToken,
			  url:item.url,
			  template:'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
			  plugin:['talk'],
			  audio:false,
			  width:750,
			  height:470,
			  // width:scwidth>1494?960:750,
			  // height:scwidth>1494?540:470
			})
			// if(showDialogPlayer==null){
			// 	showDialogPlayer = new EZUIKit.EZUIKitPlayer({
			// 	  id:'showVideoId', // 视频容器ID
			// 	  accessToken:item.accessToken,
			// 	  url:item.url,
			// 	  template:'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
			// 	  plugin:['talk'],
			// 	  audio:false,
			// 	  width:scwidth>1494?960:750,
			// 	  height:scwidth>1494?540:470
			// 	})
			// }else{
			// 	// console.log("切换了")
			// 	showDialogPlayer = new EZUIKit.EZUIKitPlayer({
			// 	  id:'showVideoId', // 视频容器ID
			// 	  accessToken:item.accessToken,
			// 	  url:item.url,
			// 	  template:'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
			// 	  plugin:['talk'],
			// 	  audio:false,
			// 	  width:scwidth>1494?960:750,
			// 	  height:scwidth>1494?540:470
			// 	})
			// 	//showDialogPlayer.play({url:item.url,accessToken: item.accessToken});
			// }
			
		},
		/**
		 * 选择基地
		 */
		clickBase(index){
			if(this.baseList[index].isclick!=1){
				return
			}
			this.currentBase = index
			this.videos = []
			setTimeout(()=>{
				//更新天气
				this.getCitysLocation()
				/**
				 * 地图数据
				 */
				this.selectMapLocation(index)
				/**
				 * 切换摄像头
				 */
				this.initVideo(index)
				//获取最新数据
				this.getLastest()
				//获取统计数据
				this.getChartsData()
			},300)
			
		}
	}
}
</script>
<style>
	@keyframes scale-up-center {
		0% {
			transform: scale(.8)
		}

		100% {
			transform: scale(1)
		}
	}

	@keyframes scale-up-center-text {
		0% {
			transform: scale(1)
		}

		100% {
			transform: scale(1.2)
		}
	}

	@keyframes text-shadow-drop-center {
		0% {
			text-shadow: 0 0 0 transparent
		}

		100% {
			text-shadow: 0px 0px 30px rgba(148, 246, 247, 1)
		}
	}
	@keyframes text-blur-out{
		0%{
			filter:blur(.01)
		}
		100%
		{
			filter:blur(12px) 
			opacity(0)
		}
	}
	.text-blur-out{
		animation:text-blur-out 1s linear infinite alternate-reverse both
	}
	.text-shadow-drop-center {
		animation: text-shadow-drop-center .8s linear infinite alternate-reverse both
	}

	.scale-up-center {
		animation: scale-up-center 1s linear infinite alternate-reverse both
	}

	.scale-up-center-text {
		animation: scale-up-center-text 1s linear infinite alternate-reverse both
	}

	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		background: #011033;
		width: 100%;
		height: 100%;
	}

	.baseClass:hover {
		cursor: pointer;
	}
	.jinyong:hover {
		cursor: not-allowed;
	}
</style>
