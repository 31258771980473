import Vue from 'vue'
import VueRouter from 'vue-router'
//import Login from '../views/login/login.vue'
import Screen from '../views/screen/screen.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   component: Login
  // },
  {
    path: '/',
    name: 'screen',
    component: Screen
  }
]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
